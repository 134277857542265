<template>
  <div class="skeletons">
    <div class="skeletons-banners">
      <StSlider :items-gap="6" disable-arrows>
        <StSkeletonLoader
          v-for="index in 4"
          :key="index"
          bg-color="var(--background-primary)"
          radius="var(--border-radius-200)"
          :shimmer-opacity="0.28"
          height="90px"
          width="90px"
        />
      </StSlider>
    </div>
    <div class="auth-block-skeleton">
      <StSkeletonLoader
        bg-color="var(--background-primary)"
        radius="var(--border-radius-200)"
        :shimmer-opacity="0.28"
        class="hero-banner"
        height="144px"
      />
      <div class="providers-skeleton">
        <StSkeletonLoader
          bg-color="var(--background-primary)"
          radius="var(--border-radius-200)"
          :shimmer-opacity="0.28"
          height="40px"
        />
        <div class="buttons-skeleton-wrapper">
          <StSkeletonLoader
            bg-color="var(--background-primary)"
            radius="var(--border-radius-200)"
            :shimmer-opacity="0.28"
            height="14px"
            width="120px"
          />
          <div class="buttons-skeleton">
            <StSkeletonLoader
              class="button-skeleton"
              bg-color="var(--background-primary)"
              radius="var(--border-radius-200)"
              :shimmer-opacity="0.28"
              height="40px"
              width="140px"
            />
            <StSkeletonLoader
              class="button-skeleton"
              bg-color="var(--background-primary)"
              radius="var(--border-radius-200)"
              :shimmer-opacity="0.28"
              height="40px"
              width="140px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.skeletons {
  background-color: var(--background-base);
}

.skeletons-banners {
  display: flex;
  gap: var(--spacing-075);
  margin-left: var(--spacing-200);
  padding-bottom: var(--spacing-200);
}

.auth-block-skeleton {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  margin: 0 var(--spacing-200);
}

.providers-skeleton {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);
  align-items: center;

  min-height: 134px;
  padding: 0 var(--spacing-200);
  padding-bottom: var(--spacing-350);
}

.buttons-skeleton-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);
  align-items: center;
}

.buttons-skeleton {
  display: flex;
  gap: var(--spacing-075);
}
</style>
