<template>
  <div class="mobile-container">
    <MHomeBanners class="home-banners" />
    <MMainCasino />
    <MMainSport class="main-sport" />
    <MMainPageCryptoBlock class="buy" />
    <div class="bottom-block">
      <MPageTournamentCard v-if="tournament" :card="tournament" />
      <LastBets
        v-if="isVisible"
        :tabs="tabs"
        header-size="mobile-large"
        class="last-bets"
        :with-header-icon="false"
      />
      <About mobile class="about" />
    </div>
  </div>
</template>

<script setup lang="ts">
import MPageTournamentCard from '@st/tournaments/components/TournamentCard/MPageTournamentCard.vue'
import { usePageTournament } from '@st/tournaments/composables/usePageTournament'
import MMainSport from '@st/bookmaker/components/MainSport/MMainSport.vue'
import MMainCasino from '@st/casino/components/MainCasino/MMainCasino.vue'
import { useLastBets } from '@st/casino/composables/useLastBets'
import MHomeBanners from '../../components/HomeBanners/MHomeBanners.vue'
import MMainPageCryptoBlock from '../../components/MainPageCryptoBlock/MMainPageCryptoBlock.vue'

definePageMeta({
  layout: 'mobile-with-footer',
})

const { tabs, isVisible } = useLastBets('main')
const { setHeaderColor } = useMobileLayout()
const { tournament } = usePageTournament('all')

onMounted(() => {
  setHeaderColor()
})

useSeoData('main')
</script>

<style scoped>
.mobile-container {
  background-color: var(--background-primary);
}

.home-banners {
  margin-top: var(--spacing-150);
}

.main-sport {
  margin-top: var(--spacing-100);
}

.bottom-block {
  background-color: var(--background-base);
  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;
}
</style>
