<template>
  <article class="main-sport-event" data-t="main-sport-event">
    <header class="header">
      <div class="tournament">
        <StIcon
          :name="sportIcon"
          :size="16"
          :data-t="`sport-icon-${sportIcon}`"
          class="sport-icon"
        />
        <p class="tournament-name" data-t="tournamentName">
          {{ tournamentFullName }}
        </p>
      </div>
      <div v-if="isLive" class="timing" data-t="matchTiming">
        <span data-t="status">{{ matchTiming }}</span>
        <span v-if="isShownClock">{{ clock }}</span>
      </div>
      <div v-else class="timing" data-t="scheduled">
        <span> {{ startDate }} {{ startTime }} </span>
      </div>
    </header>
    <div class="info">
      <NuxtI18nLink :to="event.url ?? '/'" class="logos" data-t="logos">
        <CompetitorLogo
          :competitor="event.home"
          logo-size="medium"
          class="logo first"
        />
        <CompetitorLogo
          :competitor="event.away"
          logo-size="medium"
          class="logo second"
        />
      </NuxtI18nLink>
      <CompetitorNames :event="event" class="names" compact is-mobile />
      <ScoreBoardMini
        class="scores"
        :event="event"
        :compact="isCompactScoreboard"
        only-scores
      />
    </div>
    <div class="markets" :title="marketName">
      <EventMarkets
        v-if="mainMarketConfig"
        :markets-filter="mainMarketConfig"
        :markets="mainMarket"
        :button-props="{ isShownName: true, type: 'ghost', size: 'm' }"
        data-t="markets"
      />
    </div>
  </article>
</template>

<script setup lang="ts">
import type { TSportEvent } from 'markets-store'
import { SPORT_IDS } from 'markets-store/constants'
import { getSportIcon } from 'markets-store/helpers/getSportIcon'
import type { IconName } from '@st/ui/components/StIcon/types'
import CompetitorNames from '../CompetitorNames/CompetitorNames.vue'
import ScoreBoardMini from '../ScoreBoardMini/ScoreBoardMini.vue'
import { getEventBackground } from '../../helpers/getEventBackground'

const props = defineProps<{
  event: TSportEvent
}>()

const { event } = toRefs(props)

const { tournamentFullName, isLive } = useEventInfo(event)
const isCompactScoreboard = computed(
  () => ![SPORT_IDS.TENNIS].includes(event.value.sportId),
)
const sportIcon = computed(() => getSportIcon(event.value) as IconName)

const { startDate, startTime } = useStartDate(event)
const { matchTiming, clock, isShownClock } = useLiveClock(event)
const { mainMarketConfig, mainMarket, marketName } = useMainMarket(event)
const eventBackground = computed(
  () => `var(${getEventBackground(props.event.sportId).header})`,
)
</script>

<style scoped>
.main-sport-event {
  display: flex;
  flex-direction: column;

  padding: var(--spacing-150) var(--spacing-150) var(--spacing-125)
    var(--spacing-150);

  background: v-bind(eventBackground);
  border-radius: var(--border-radius-150);
}

.info {
  display: flex;
  gap: var(--spacing-075);

  /* stylelint-disable */
  margin-top: 9px;
  margin-bottom: 11px;

  /* stylelint-enable */
}

.header {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
  justify-content: space-between;
}

.names {
  flex-grow: 1;
  min-width: 0;
}

.timing {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  padding-right: var(--spacing-050);

  font: var(--desktop-text-xs-medium);
  color: var(--text-primary);
}

.tournament {
  display: flex;
  flex-shrink: 1;
  gap: var(--spacing-050);
  align-items: center;

  min-width: 0;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}

.tournament-name {
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sport-icon {
  min-width: 16px;
  color: var(--text-primary);
}

.markets {
  padding-right: var(--spacing-100);
}

.logos {
  display: flex;
  align-items: center;
  width: 70px;

  .first {
    z-index: 1;
    box-shadow: 0 0 0 2px v-bind(eventBackground);
  }

  .second {
    position: relative;
    left: -2px;
  }
}
</style>
