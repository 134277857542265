<template>
  <section class="top-banners" data-t="home-banners">
    <MHomeBannersSkeleton
      v-if="isLoading || !isMounted"
      data-t="loading-skeleton"
    />
    <template v-else>
      <div v-if="banners.length" class="banners">
        <StMBanners
          :is-loading="isLoadingMobileMenuBanners"
          :items="banners"
          data-t="secondary-banners"
          size="l"
        />
      </div>

      <div
        v-if="isNonAuthBannerVisible"
        class="banner-block"
        data-t="non-auth-main-banner"
        :style="`background-image: url(${nonAuthMainBanner.publicUploadUrl})`"
      >
        <NuxtI18nLink
          :to="nonAuthMainBanner.url"
          class="hero-banner"
          prefetch
        />
        <AuthProviders />
      </div>
      <div
        v-if="isAuthBannerVisible"
        class="banner-block banner-block-auth"
        :style="`background-image: url(${authMainBanner.publicUploadUrl})`"
        data-t="auth-main-banner"
      >
        <NuxtI18nLink
          :to="authMainBanner.url"
          class="hero-banner hero-banner-auth"
          prefetch
        />
        <StButton
          class="auth-block-button"
          type="ghost"
          size="l"
          data-t="bonuses-button"
          icon="gift-solid"
          to="/bonuses"
          :label="t('bonuses.myBonuses')"
          is-left-icon
        />
      </div>
    </template>
  </section>
</template>

<script setup lang="ts">
import { useUserStore } from '@st/user/stores/useUserStore'
import { useHomeBanners } from '../../composables/useHomeBanners'
import MHomeBannersSkeleton from './MHomeBannersSkeleton.vue'

const { isAuthenticated } = storeToRefs(useUserStore())
const { t } = useI18n()
const {
  authMainBanner,
  nonAuthMainBanner,
  secondaryBanners: banners,
  isLoadingSecondaryBanners: isLoadingMobileMenuBanners,
  isLoading,
  isMounted,
} = useHomeBanners({ isMobile: true })

const isNonAuthBannerVisible = computed(
  () => !isAuthenticated.value && nonAuthMainBanner.value,
)
const isAuthBannerVisible = computed(
  () => isAuthenticated.value && authMainBanner.value,
)
</script>

<style scoped>
.banner-block {
  display: flex;
  flex-direction: column;

  margin: 0 var(--spacing-200);

  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--border-radius-200);
}

.banner-block-auth {
  background-position: center;
}

.hero-banner {
  display: block;
  width: 100%;
  height: 172px;
}

.hero-banner-auth {
  height: 144px;
}

.banners {
  margin-bottom: var(--spacing-200);
}

.top-banners {
  padding-bottom: var(--spacing-125);
  background-color: var(--background-base);
}

.auth-block-button {
  margin: 0 var(--spacing-200) var(--spacing-200);
}
</style>
