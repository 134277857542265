<template>
  <MStSection
    class="main-casino"
    data-t="main-casino"
    :header-data-t="sectionDataT"
    link="/casino"
    size="m"
    :title="t('casino.casino')"
    :button-text="t('casino.all')"
    :button-counter="allGamesCounter"
  >
    <template #addition>
      <StMobileTabs
        v-if="activeTab"
        v-model="activeTab"
        :tab-items="tabs"
        type="row"
        class="tabs"
      />
    </template>
    <template #default>
      <MGameSlider ref="games-slider" :rows="sliderRows">
        <template v-if="isLoading">
          <MGameCardSkeleton v-for="i in 8" :key="i" />
        </template>
        <template v-else>
          <MGameCard v-for="game of games" :key="game.gameId" :game="game" />
        </template>
        <template #after>
          <div class="show-all">
            <StButton
              icon="chevron-right"
              size="m"
              type="gray"
              data-t="show-all-e4d6"
              to="/casino"
            />
            <span>
              {{ t('casino.all') }}
              <span class="current-count" data-t="show-all-counter-8nn5">
                {{ showMoreCounter }}
              </span>
            </span>
          </div>
        </template>
      </MGameSlider>
    </template>
  </MStSection>
  <MProvidersSlider class="providers" size="m" :with-title-icon="false" />
</template>

<script setup lang="ts">
import MStSection from '@st/ui/components/StSection/MStSection.vue'
import MGameSlider from '../MGameSlider/MGameSlider.vue'
import { useMainCasino } from './useMainCasino'
import MGameCard from '../GameCard/MGameCard.vue'
import MGameCardSkeleton from '../GameCard/MGameCardSkeleton.vue'
import MProvidersSlider from '../ProvidersSlider/MProvidersSlider.vue'

const { allGamesCounter, tabs, activeTab, games, showMoreCounter, isLoading } =
  useMainCasino()

const { t } = useI18n()

const gamesSlider =
  useTemplateRef<InstanceType<typeof MGameSlider>>('games-slider')

watch(activeTab, () => {
  gamesSlider.value?.scrollToStart()
})

const sectionDataT = {
  title: 'link-1ehw-casino',
  button: 'all-casino-button-0hjb',
  buttonCounter: 'games-total-eeq1',
}

const sliderRows = computed(() => (games.value.length > 5 ? 2 : 1))
</script>

<style scoped>
.main-casino {
  background-color: var(--background-base);
}

.show-all {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);
  align-items: center;
  justify-content: center;

  min-width: 92px;
  margin-left: var(--spacing-100);

  font: var(--mobile-text-medium);
}

.current-count {
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.games-total {
  font: var(--mobile-text-semibold);
  color: var(--text-tertiary);
}

.mobile-tabs {
  padding: 0;

  & :deep(.mobile-tab:first-child) {
    padding-left: 0;
  }
}

.providers {
  background-color: var(--background-base);
  border-radius: 0 0 var(--border-radius-200) var(--border-radius-200);
}
</style>
