<template>
  <div data-t="main-sport">
    <MStSection
      class="events-section"
      :header-data-t="sectionDataT"
      link="/sport"
      button-link="/sport/all"
      :title="t('sportHomePage.sport')"
      :button-text="t('sportHomePage.all')"
      :button-counter="allEventsCount"
      size="m"
    >
      <template #addition>
        <StMobileTabs
          v-if="tabs.length"
          v-model="activeTab"
          :tab-items="tabs"
          type="row"
          class="mobile-tabs"
          @update:model-value="onTabUpdate"
        />
        <StSkeletonLoader
          v-else
          bg-color="var(--background-primary)"
          radius="var(--border-radius-200)"
          :shimmer-opacity="0.28"
          height="30px"
          width="260px"
        />
      </template>
      <template #default>
        <div class="events" data-t="events">
          <div ref="scroller" class="scroller">
            <MainSportEvent
              v-for="event in eventsForRender"
              :key="event.id"
              :event="event"
              class="event"
              data-t="event"
            />
            <div
              v-for="item in skeletonsCount"
              :key="item"
              class="event event-empty"
            />
            <div class="show-all">
              <StButton
                :to="currentEventsLink"
                icon="chevron-right"
                size="m"
                type="gray"
              />
              <span>
                {{ t('sportHomePage.all') }}
                <span class="current-count">{{ currentEventsCount }}</span>
              </span>
            </div>
          </div>
        </div>
      </template>
    </MStSection>
    <MStSection
      :title="t('sportHomePage.topTournaments')"
      :with-chevron="false"
      size="m"
      class="tournaments-section"
    >
      <MTopTournaments
        is-live-visible
        class="top-tournaments"
        data-t="top-tournaments"
      />
    </MStSection>
  </div>
</template>

<script setup lang="ts">
import MStSection from '@st/ui/components/StSection/MStSection.vue'
import MainSportEvent from '../MainSportEvent/MainSportEvent.vue'
import MTopTournaments from '../TopTournaments/MTopTournaments.vue'
import { useMainSportSection } from './useMainSportSection'

const { t } = useI18n()

const {
  activeTab,
  tabs,
  eventsForRender,
  allEventsCount,
  currentEventsLink,
  currentEventsCount,
} = useMainSportSection()

const scroller = ref<HTMLDivElement | null>(null)
function onTabUpdate() {
  if (scroller.value) {
    scroller.value.scrollTo({ left: 0, behavior: 'smooth' })
  }
}

const MIN_SLIDER_ITEMS_COUNT = 1
const skeletonsCount = computed(() =>
  Math.max(0, MIN_SLIDER_ITEMS_COUNT - eventsForRender.value.length),
)

const sectionDataT = {
  buttonCounter: 'events-count',
}
</script>

<style scoped>
.events-section {
  background: var(--background-base);
  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;
}

.mobile-tabs {
  padding: 0;

  & :deep(.mobile-tab:first-child) {
    padding-left: 0;
  }
}

.events {
  overflow: hidden;
}

.scroller {
  scrollbar-color: transparent transparent;
  scrollbar-width: none;

  overflow-x: auto;
  display: flex;
  gap: var(--spacing-100);

  white-space: nowrap;
}

.event {
  min-width: 316px;

  &.event-empty {
    opacity: 0.4;
    background-color: var(--background-primary);
    border-radius: var(--border-radius-150);
  }
}

.show-all {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);
  align-items: center;
  justify-content: center;

  min-width: 92px;
  height: 132px;

  font: var(--mobile-text-medium);
}

.current-count {
  font: var(--mobile-text-regular);
  color: var(--text-tertiary);
}

.tournaments-section {
  background: var(--background-base);
  border-radius: 0 0 var(--border-radius-200) var(--border-radius-200);
}

.top-tournaments {
  min-height: 106px;
  background: var(--background-base);

  /**
   * @TODO:
   * после мержа ST-7368 в рамках адаптации изменений секций также убрать
   * эти маргины на уровне непосредственно компонента 
   */
  & :deep(.item:first-child) {
    margin-left: 0;
  }

  & :deep(.item:last-child) {
    margin-right: 0;
  }
}
</style>
