<template>
  <section class="crypto-block" data-t="crypto-block">
    <div class="crypto-text">
      <h3>{{ t('buyCrypto.noCrypto') }}</h3>
      <p>{{ t('buyCrypto.card') }}</p>
    </div>
    <div class="crypto-actions" data-t="crypto-actions">
      <ClientOnly>
        <div class="button-wrapper">
          <StButton
            v-if="isAuthenticated"
            :label="t('buyCrypto.button')"
            type="secondary"
            size="l"
            replace
            :disabled="!isEnabledBuyCrypto"
            :to="{ query: { modal: 'payments', tab: 'deposit' } }"
          />
          <StButton
            v-else
            :label="t('buyCrypto.button')"
            type="secondary"
            size="l"
            replace
            :disabled="!isEnabledBuyCrypto"
            :to="{ query: { modal: 'login' } }"
          />
        </div>
      </ClientOnly>
      <div v-if="!isEnabledBuyCrypto" class="soon">
        {{ t('buyCrypto.soon') }}
      </div>
    </div>
    <div class="crypto-badges">
      <div
        v-for="payment in paymentSystems"
        :key="payment.id"
        class="payment-item"
        data-t="payment-item"
      >
        <img :src="payment.icon" :alt="payment.id" height="22" />
      </div>
    </div>
    <div class="tooltip-wrapper">
      <BuyCryptoTooltip is-home-page />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useUserStore } from '@st/user/stores/useUserStore'
import visa from '@st/payments/components/PaymentsModal/assets/visa-white.svg'
import mastercard from '@st/payments/components/PaymentsModal/assets/mastercard.svg'
import googlePay from '@st/payments/components/PaymentsModal/assets/google-pay.svg'
import applePay from '@st/payments/components/PaymentsModal/assets/apple-pay.svg'

const paymentSystems = [
  { id: 'visa', icon: visa },
  { id: 'mastercard', icon: mastercard },
  { id: 'google-pay', icon: googlePay },
  { id: 'apple-pay', icon: applePay },
]

const { isEnabledBuyCrypto } = useBuyCryptoEnabled()
const { isAuthenticated } = storeToRefs(useUserStore())

const { t } = useI18n()
</script>

<style scoped>
.crypto-block {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: var(--spacing-350) var(--spacing-200) var(--spacing-200);

  background-color: var(--background-primary);
}

.crypto-text {
  width: 100%;
  margin-bottom: var(--spacing-200);
  text-align: center;

  & h3 {
    margin: 0 0 var(--spacing-025) 0;
    font: var(--mobile-title-2-semibold);
    color: var(--text-primary);
  }

  & p {
    margin: 0;
    font: var(--mobile-text-regular);
    color: var(--text-tertiary);
  }
}

.crypto-badges {
  overflow: auto;
  display: flex;
  gap: var(--spacing-200);
  max-width: 100%;
}

.crypto-actions {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: var(--spacing-300);
}

.soon {
  user-select: none;

  position: absolute;
  top: -8px;
  right: -10px;

  padding: var(--spacing-025) var(--spacing-050);

  font: var(--desktop-text-xxs-medium);
  color: var(--text-secondary);

  background-color: var(--background-ghost-tertiary);
  border-radius: var(--border-radius-full, 999px);
}

.tooltip-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);
  align-items: center;
  align-self: stretch;

  margin-top: var(--spacing-300);
  padding: var(--spacing-250) 0 var(--spacing-050) 0;

  border-top: var(--border-width-light) solid var(--border-secondary);
}
</style>
